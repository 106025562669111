/*Generales*/

*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
}

body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    background: #1b1b1b;  /* fallback for old browsers*/ 
    background: -webkit-linear-gradient(to bottom, #1b1b1b, #222222);  /* Chrome 10-25, Safari 5.1-6*/ 
    background: linear-gradient(to bottom, #1b1b1b ,#222222); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
}

.clearfix{ 
    float:none;
    clear: both;
}

/*Barra de navegacion*/
header{
    height: 10%;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
}

header ul{
    display: block;
    height: inherit;
    margin: 0px auto;
}

header ul li{
    display: inline-block;
    margin: 0px auto;
    text-align: center;
    line-height: 80px;
}

header a{
    height: inherit;
    text-decoration: none;
    color:rgb(233, 233, 233);
    transition: all 300ms;
}

header a:hover,
.actived{
    text-decoration: underline;
    text-decoration-color: #38C6BA;
    color:rgb(233, 233, 233);
}

/*Logo*/

#logo{
    font-family: 'Franklin Gothic Medium', 'Franklin Gothic', 'ITC Franklin Gothic';
    background: -webkit-linear-gradient(-45deg,#38C6BA, #28A2BF,  #23a6d5,  #B82AAF, #873FA8);
    font-size: 1.6em;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 200ms ease-in-out;
}

#logo:hover{
    transform: scale(1.3, 1.3);
}

#toggler-icon{
    display:inline-block;
    width: 90%;
    height: 90%;
    text-align: center;
    transform: rotate(90deg);
    color: #38c6ba71;
}
.navbar-dark .navbar-toggler {
    border-color: #38c6ba1e;
}
/*contenido principal*/

#content{
    min-height: 100vh;
    width: 75%;
    margin:0px auto; 
    box-shadow: 0px 0px 30px #38C6BA;
    background: rgba(rgb(222, 222, 222)); 
    padding-top: 1%;
    animation: box-shadow-pulse 3s infinite;
}

@keyframes box-shadow-pulse {
    0% {
      box-shadow: 0px 0px 30px #38C6BA;
    }
    50% {
      box-shadow: 0px 0px 60px #38C6BA;
    }
    100% {
      box-shadow: 0px 0px 30px #38C6BA;
    }
  }

/* footer*/

footer{
    width: 100%;
    text-align: center;
    padding: 1.5em;
    color:rgb(218, 218, 218);
}

@keyframes heartbeat {
    from { transform: none; }
    50% { transform: scale(1.4); }
    to { transform: none; }
}

.heart{
	display: inline-block;
	font-size: 17px;
	color: rgb(218, 218, 218);
	animation: heartbeat 3000ms infinite;
	transform-origin: center;
}

/*Sobre mi*/

#title{
    width: 70%;
    margin: 0px auto;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1%;
    margin-bottom: 2%;
}



#title h1{
    font-family:'Courier New','Helvetica';
    font-size:35px;
    color: #38C6BA;
    border-bottom:1px solid rgb(134, 134, 134);
    padding-bottom: 5px;
}
#title h1{
    transition:color 500ms;
}
#title h1:hover{
    
    background: transparent;
    color: #28A2BF;
}


#title h2{
    font-size: 20px;
    color:rgb(233, 233, 233);
    margin-top: 5px;
    margin-bottom: 5px;
}

/*About*/
article.about{
    width: 70%;
    margin:0px auto;
    margin-left: 1%;
    margin-bottom:5%;
    font-size: 20px;

}

.about h2{
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color:rgb(233, 233, 233);
}

.about p{
    padding-top:20px;
    padding-bottom:30px;
    color: rgb(233, 233, 233);
    text-align: justify;
}
#title img.aboutImg{
    height: auto;
    width: auto;
    max-height: 530px;
    max-width: 530px;
    align-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.about a{
    text-decoration: none;
    text-shadow: 3px 3px 4px #38C6BA;
    color:rgb(233, 233, 233);
}

.about .bullets{
    color:rgb(170, 170, 170);
}


/*Home*/
article.home{
    /* min-height: 90%; */
    margin:0px auto;
    width: 100%;
    /* margin-bottom:10%; */
    font-size: 20px;
    padding-top: 5%;


}

.skills{
    border-bottom:1px solid rgb(134, 134, 134);
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom:20px;
    font-size: 20px;

}
.skills 
.backend,
.frontend{
    margin-bottom: 5%;
    margin-bottom: 5%;
}

.skills h3{
    border-bottom: 1px solid transparent;
    margin-bottom: 1%;
    margin-bottom: 4%;
    color: #28A2BF;
}

.skills h4{
    font-size: 20px;
    margin-bottom: 1%;
    margin-bottom: 4%;
    color:rgb(233, 233, 233);
}

.octagon{
    position: relative;
    display: inline-block;
    width: 9vw; /* 20% del ancho de la pantalla */
    height: 9vw; /* 20% del ancho de la pantalla */
    min-width: 100px; /* tamaño mínimo */
    min-height: 100px; /* tamaño mínimo */
    max-width: 120px; /* tamaño máximo */
    max-height: 120px; /* tamaño máximo */

    margin: 5px; /* Agrega un poco de margen entre octágonos */
    clip-path: polygon(
        30% 0%, 
        70% 0%, 
        100% 30%, 
        100% 70%, 
        70% 100%, 
        30% 100%, 
        0% 70%, 
        0% 30%
    );
    transition: transform 1500ms ease-in-out;   
}
.octagon:hover{
    transform: rotate(360deg);
}

.octagon h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#1b1b1b;
  font-size: inherit;
}

.octa-1{
    background: #38C6BA;
}
.octa-2{
    background: #28A2BF;
}
.octa-3{
    background: #B82AAF;
}
.octa-4{
    background: #873FA8;
}
.octa-5{
    background: #58C07A;
}
.octa-6{
    background: #BDCD4C;
}
.octa-7{
    background: #f1b04e;
}
.octa-8{
    background: #E14B66;
}


.social-network{
    width: 70%;
    margin:0px auto;
    margin-top:4%;
    margin-bottom:70px;
    font-size: 21px;
    text-align: center;  
}

.social-network a,
#contact a{
    display: inline-block;
    text-shadow: 3px 3px 4px #38C6BA;
    text-decoration: none;
    padding-right: 1%;
    padding-left: 1%;
    color:rgb(233, 233, 233);
}

.social-network h3{
    width: 70%;
    margin:0px auto;
    margin-top:10%;
    margin-bottom:70px;
    font-size: 23px;
    text-align: center;  
}

/*projects*/

.project{
    padding-bottom: 8%;
}

.project a{
    text-decoration: none;
    color: rgb(233, 233, 233);
}

.overflow{
    height: 50%;
    overflow: hidden;
}

.card-group{
    height: 98%;
}
.overflow .card-img-top{
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

.overflow .card-img-top:hover{
    transform: scale(1.8);
}


/*project*/

.projectDescription{
    position: flex;
    padding: 10px;
    margin-top: 3%;
    display: block;
    justify-content: center;
    align-items: center;
    color: rgb(233, 233, 233);
}



.projectDescription p,
.projectDescription  a
{
    color:  rgb(233, 233, 233);
}

.projectDescription  a{
    text-decoration: none;
}

.projectDescription  span{
    text-shadow: 2px 2px 3px #38C6BA;
}

.projectDescription  p a{
    cursor: pointer;
}



/*Contact*/
#contact h4{
    color: rgb(233, 233, 233);
    padding-top: 8%;
    margin-bottom:3%;
    font-size: 20px;
    text-align: center;  
}

#email i{
    color:  rgb(233, 233, 233);
    text-shadow: none;
    transition: all 300ms;
}

#email i:hover{
    text-shadow: 1px 1px 2px #38C6BA;
}



/*404*/
svg {
    position: flex;
    top: 10%;
    left: 3%;
    margin-top: 9%;
    margin-left:3%;
  }
  .message-box {
    color: #FFF;
    font-weight: 300;
    margin-bottom: 25%;
  }

  .message-box h1 {
    font-size: 60px;
  }


  
  #Polygon-1 , #Polygon-2 , #Polygon-3{
    animation: float 1s infinite ease-in-out alternate;
  }

  .message-box h1, .message-box p {
    animation: float 1s infinite ease-in-out alternate;
  }

  #Polygon-2 {
    animation-delay: .2s; 
  }

  #Polygon-3 {
    animation-delay: .5s; 
  }

  .message-box p{
    animation-delay: .1s; 
  }
  
  @keyframes float {
      100% {
      transform: translateY(60px);
    }
  }
  @media (max-width: 150px) {
    svg {
      position: flex;
    }
  }


  /*
Paleta
background: #38C6BA;
background: #28A2BF;
background: #B82AAF;
background: #873FA8;
background: #58C07A;
background: #BDCD4C;
background: #F7B34A;
background: #E14B66;
*/